import { createGlobalStyle } from 'styled-components'

const BasicGlobalStyle = createGlobalStyle`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  input, select, textarea, button {
    font-family: ${({ theme }) => theme.font.family.regular};
  }
  
  html,body{
    font-family: ${({ theme }) => theme.font.family.regular};
    margin: 0;
    line-height: 1.5;
  }

  body {
    background-color: ${(props) => props.theme.color.background};
    color: ${(props) => props.theme.color.text}
  }
`

export default BasicGlobalStyle
