import React, { useState } from 'react'
import styled from 'styled-components'

import { Description, ErrorMessage, Label } from './styledElements'

const DisplayOnlyText = styled.div`
  font-size: ${({ theme }) => theme.font.size.normal};
  font-family: ${({ theme }) => theme.font.family.regular};
  color: ${({ theme }) => theme.color.success};
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: transparent;
  display: block;
  margin-top: ${(props) => (props.label ? '0.125rem' : '0')};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  height: 1.5rem;
`

const StyledInput = styled.input`
  appearance: none;
  -webkit-appearance: none;
  background-color: unset;
  font-size: ${({ theme }) => theme.font.size.normal};
  font-family: ${({ isNumber, theme }) => (isNumber ? theme.font.family.numberInput : theme.font.family.regular)};
  color: ${({ color, theme }) => (color && theme.color[color] ? theme.color[color] : theme.color.input)};
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: ${({ showInvalidState, invalid, hasErrorMessage, theme }) =>
    showInvalidState && (invalid || hasErrorMessage) ? theme.color.error : theme.color.inputBorder};
  display: block;
  margin-bottom: 0.125rem;
  margin-top: ${(props) => (props.label ? '0.125rem' : '0')};
  padding: 0.25rem 0.5rem 0 0.5rem;
  width: 100%;
  height: 2rem;

  &::placeholder {
    color: ${({ theme }) => theme.color.text};
    font-style: italic;
    opacity: 0.7;
    font-family: ${({ theme }) => theme.font.family.regular};
    font-size: ${({ theme }) => theme.font.size.placeholder};
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.color.accent};
    border-radius: ${(props) => props.theme.borderRadius.input || '4px'};
    border-bottom-color: ${({ theme }) => theme.color.accent};
  }
`

const Input = ({
  displayOnly = false,
  label,
  errorMessage,
  description,
  iconOnRight,
  showErrorOnBlur = false,
  id,
  onChange,
  autoFocus = false,
  required = false,
  value,
  type = 'text',
  readOnly = false,
  invalid = false,
  color,
  className /* styled-components sets this if the css attr is used */,
  cssForInput,
  cssForLabel,
  cssForInputWrapper,
  isNumber = false,
  ...props
}) => {
  const [blurred, setBlurred] = useState(false)
  const inputProps = {}
  if (onChange) {
    inputProps.onChange = onChange
    inputProps.value = value
  } else {
    inputProps.defaultValue = value
  }
  if (showErrorOnBlur) {
    inputProps.onBlur = () => setBlurred(true)
  }

  return (
    <div className={className}>
      {label && (
        <Label htmlFor={id} displayOnly={displayOnly} css={cssForLabel}>
          {label}
        </Label>
      )}
      <div
        css={`
          position: relative;
          ${cssForInputWrapper}
        `}
      >
        {displayOnly ? (
          <DisplayOnlyText label={label}>{value}</DisplayOnlyText>
        ) : (
          <StyledInput
            isNumber={isNumber}
            color={color}
            id={id}
            type={type}
            autoFocus={autoFocus}
            readOnly={readOnly}
            {...inputProps}
            required={required}
            invalid={invalid}
            showInvalidState={!showErrorOnBlur || blurred}
            hasErrorMessage={errorMessage && errorMessage !== ''}
            css={cssForInput}
            {...props}
          />
        )}
        <div
          css={`
            position: absolute;
            top: 8px;
            right: 8px;
            height: 16px;
            width: 16px;
            user-select: none;
          `}
        >
          {iconOnRight}
        </div>
      </div>
      <Description
        css={`
          display: ${!displayOnly && description ? null : 'none'};
        `}
      >
        {description}
      </Description>
      <ErrorMessage
        css={`
          display: ${!displayOnly && errorMessage && (!showErrorOnBlur || blurred) ? null : 'none'};
        `}
      >
        {errorMessage}
      </ErrorMessage>
    </div>
  )
}

export default Input
