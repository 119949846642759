export const getTransaction = async (id) => {
  const url =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8096/api/payment/intent/'
      : 'https://cleanpay.mokajava.com/api/payment/intent/'

  const response = await fetch(`${url}${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (response.status === 200) return response.json()
  return { error: 'Invalid id' }
}

export const getAccessToken = async () => {
  const url =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8096/api/payment/access/'
      : 'https://cleanpay.mokajava.com/api/payment/access/'
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  if (response.status === 200) return response.json()
  return { error: 'Invalid request' }
}

export const submitPayment = async (request) => {
  const url = 'https://cosmo.tech/api/payment/sales'
  const accessTokenRequest = await getAccessToken()
  if (accessTokenRequest.error !== undefined) {
    return { error: 'You are not authorized to do this request.' }
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessTokenRequest.accessToken}`,
    },
    body: JSON.stringify({ ...request }),
  })
  if (response.status === 200) return response.json()
  if (response.status === 401) return { error: 'You are not authorized to do this request.' }
  if (response.status === 500) return { error: 'There was a service error with the provider.' }

  return { error: 'Invalid payment request.' }
}
