import styled, { css } from 'styled-components'

export const Label = styled.label`
  color: ${({ theme }) => theme.color.label};
  font-family: ${({ theme }) => theme.font.family.label};
  font-size: ${({ theme }) => theme.font.size.label};
  display: block;
  margin-bottom: 0.25rem;
`

export const Description = styled.span`
  margin-top: 0.4rem;
  font-family: ${({ theme }) => theme.font.family.regular};
  color: ${({ theme }) => theme.color.textMuted};
  font-size: ${({ theme }) => theme.font.size.extraSmall};
  display: block;
`

export const ErrorMessage = styled(Description)`
  padding-left: 0.5rem;
  color: ${({ theme }) => theme.color.error};
`

/**
 * Adds spin animation
 */
export const spin = css`
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export function onDesktop(cssStrings, ...interpolations) {
  return css`
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
      ${css(cssStrings, ...interpolations)}
    }
  `
}

export const Container = styled.main`
  background: ${(props) => props.theme.color.background};
  width: 100%;
  max-width: 414px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 414px) {
    max-width: min(calc(100vw), 414px);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* border: 1pt solid ${(props) => props.theme.color.accent}; */
    max-width: min(calc(100vw - 6rem), 780px);
    border-radius: ${(props) => props.theme.borderRadius.container || '1rem'};
    box-shadow: 0 0 8px 2px ${(props) => props.theme.color.shadow};
  }
`
