import creditCardType, { getTypeInfo } from 'credit-card-type'
import luhn from 'luhn'
import React, { useState } from 'react'
import Button from '../simple-components/Button'
import Input from '../simple-components/Input'
import { onDesktop } from '../simple-components/styledElements'

const prettyCardNumber = (cardNumber, cardType) => {
  var card = getTypeInfo(cardType)

  if (card) {
    var offsets = [].concat(0, card.gaps, cardNumber.length)
    var components = []

    for (var i = 0; offsets[i] < cardNumber.length; i++) {
      var start = offsets[i]
      var end = Math.min(offsets[i + 1], cardNumber.length)
      components.push(cardNumber.substring(start, end))
    }

    return components.join(' ')
  }

  return cardNumber
}

/**
 * SUCCESS: 4242424242424242 734|934|843
 * SUCCESS: 5555555555554444 734|934|843
 * SUCCESS: 2223003122003222 734|934|843
 * SUCCESS: 6011111111111117 734|934|843
 * ERROR: 4000000000009979 !734|!934|!843
 * ERROR: 4100000000000019 !734|!934|!843
 * ERROR: 378282246310005 any cvv
 */

// const genCard = (card) => {
//   const detailed = creditCardType(card.substring(0, 4))[0]
//   return {
//     simple: card,
//     display: prettyCardNumber(card, detailed.type),
//     detailed,
//     maxLength: Math.max(...detailed.lengths),
//   }
// }

const PaymentForm = ({ transactionInfo, handlePayment }) => {
  const [cardNumber, setCardNumber] = useState({ simple: '', display: '', maxLength: 18 })
  // const [cardNumber, setCardNumber] = useState(genCard('4242424242424242'))
  const [nameOnCard, setNameOnCard] = useState('')
  const [expiryDate, setExpiryDate] = useState('')
  const [securityCode, setSecurityCode] = useState('')
  const [showErrors, setShowErrors] = useState(false)

  // const [showDetailedReview, setShowDetailedReview] = useState(false)

  const validateCard = () => {
    return (
      cardNumber.detailed &&
      cardNumber.detailed.lengths.includes(cardNumber.simple.length) &&
      luhn.validate(cardNumber.simple)
    )
  }

  const validateExpiryDate = () => {
    const thisYear = parseInt(('' + new Date().getFullYear()).slice(2))

    const split = expiryDate.split('/')
    return parseInt(split[0]) < 13 && parseInt(split[1]) >= thisYear
  }

  const isValid =
    cardNumber.detailed !== undefined &&
    cardNumber.detailed.code.size === securityCode.length &&
    validateCard() &&
    validateExpiryDate() &&
    nameOnCard.length > 1

  const pay = async () => {
    if (!isValid) {
      setShowErrors(true)
      return
    }
    const expiryDateSplit = expiryDate.split('/')

    const request = {
      ...transactionInfo,
      customisation: undefined,
      order: {
        ...transactionInfo.order,
        items: undefined,
      },
      card: {
        name: nameOnCard,
        pan: cardNumber.simple,
        cvv: securityCode,
        expiry: {
          month: parseInt(expiryDateSplit[0]),
          year: parseInt(expiryDateSplit[1]),
        },
      },
    }
    setCardNumber({ simple: '', display: '' })
    setExpiryDate('')
    setSecurityCode('')

    handlePayment(request)
  }

  const onCardNumberChange = (value) => {
    let simple = value.replace(/[^\d]/gi, '')
    let display = simple

    if (simple.length < 4) {
      setCardNumber({ simple, display, detailed: undefined, maxLength: 18 })
      return
    }

    let maxLength = cardNumber.maxLength

    const matches = creditCardType(simple.substring(0, 4))
    const detailed = matches.length > 0 ? matches[0] : cardNumber.detailed

    if (detailed) {
      maxLength = Math.max(...detailed.lengths)
      if (simple.length > maxLength) simple = simple.slice(0, maxLength)
      display = prettyCardNumber(simple, detailed.type)
      setCardNumber({ simple, display, detailed, maxLength })
    } else {
      if (simple.length > maxLength) simple = simple.slice(0, maxLength)
      setCardNumber({ simple, display: simple, detailed, maxLength })
    }
  }

  const onExpiryDateKeyDown = (e) => {
    const key = e.key.length === 1 && !e.ctrlKey && !e.altKey ? e.key.replace(/[^\d/]/gi, '') : '_'
    if (key === '') {
      e.preventDefault()
      return
    } else if (key === '_') {
      return
    } else if (e.target.selectionStart !== e.target.selectionEnd) {
      /* user made a selection */
      return
    }
    if (key >= 0 && key <= 9) {
      switch (expiryDate.length) {
        case 0:
          if (key > 1) {
            setExpiryDate(`0${key}/`)
            e.preventDefault()
          } else {
            // setExpiryDate(key)
          }
          break
        case 1:
          if (expiryDate == 1 && key > 2) break
          if (expiryDate == 0 && key == 0) break
          if (expiryDate === '0' || expiryDate === '1') {
            setExpiryDate(`${expiryDate}${key}/`)
            e.preventDefault()
          }
          break
        case 2:
          if (!expiryDate.includes('/') && expiryDate < 13) {
            setExpiryDate(`${expiryDate}/${key}`)
            e.preventDefault()
          }
          break
        default:
      }
    }
    if ((key === '/') & expiryDate.includes('/')) e.preventDefault()
    if ((key === '/') & (expiryDate.length === 0)) e.preventDefault()
    if ((key === '/') & (expiryDate === '0')) e.preventDefault()

    if (key === '/' && !expiryDate.includes('/') && expiryDate.length == 1 && expiryDate !== '0') {
      setExpiryDate(`0${expiryDate}/`)
      e.preventDefault()
    }

    if (expiryDate.length === 5) {
      e.preventDefault()
    }
  }

  const onExpiryDateChange = (e) => {
    let value = e.target.value.replace(/[^\d/]/gi, '')
    const split = value.split('/')
    value = split.shift()
    if (split.length > 0) value = value + '/' + split.join('')

    if (value.length > 7) {
      value = value.slice(0, 7)
    }

    if (value.length > 2) {
      switch (value.length) {
        case 3:
        case 4:
          if (value.includes('/')) break
          value = value.slice(0, 2) + '/' + value.slice(2)
          break
        case 5:
        case 6:
          if (value.includes('/')) break
          value = value.slice(0, 2) + '/' + value.slice(2, 4)
          break
        case 7:
          // attempt to handle pasting MM/YYYY in the field
          if (value.includes('/')) {
            value = value.replace('/', '')
            value = value.slice(0, 2) + '/' + value.slice(value.length - 2)
          } else {
            value = value.slice(0, 2) + '/' + value.slice(2, 4)
          }
      }
    }
    setExpiryDate(value)
  }

  return (
    <>
      <form
        css={`
          display: grid;
          gap: 1rem;
          grid-template-columns: 1fr;
          ${onDesktop`grid-template-columns: 1fr 1fr;`}
        `}
      >
        <Input
          type='text'
          autocomplete='cc-name'
          id='cc-name'
          name='cc-name'
          css={`
            ${onDesktop`grid-column: span 2;`}
          `}
          label='Name on card'
          placeholder='Enter your name as it appears on the card'
          value={nameOnCard}
          onChange={(e) => setNameOnCard(e.target.value)}
          errorMessage={nameOnCard.length > 1 ? '' : 'Please enter your name.'}
          showErrorOnBlur={!showErrors}
        />
        <Input
          pattern='[0-9]*'
          isNumber={true}
          css={`
            ${onDesktop`grid-column: span 2;`}
          `}
          label='Card number'
          inputMode='numeric'
          type='text'
          autocomplete='cc-number'
          id='cc-number'
          name='cc-number'
          placeholder='Enter your card number'
          value={cardNumber.display}
          onChange={(e) => onCardNumberChange(e.target.value)}
          showErrorOnBlur={!showErrors}
          errorMessage={validateCard() ? '' : 'Please enter a valid card number.'}
          maxlength={cardNumber.maxLength}
          iconOnRight={
            cardNumber.detailed && (
              <div
                css={`
                  position: absolute;
                  width: max-content;
                  right: 0;
                  top: -6px;
                  color: ${(props) => props.theme.color.accent};

                  img {
                    height: 26px;
                  }
                `}
              >
                <img
                  src={window.location.origin + `/processor/${cardNumber.detailed.type}.svg`}
                  alt={cardNumber.detailed.niceType}
                />
              </div>
            )
          }
        />
        <Input
          pattern='[0-9]*'
          inputMode='numeric'
          type='text'
          autocomplete='cc-exp'
          id='cc-exp'
          name='cc-exp'
          isNumber={true}
          label='Expiry date'
          placeholder='Enter the expiry date (MM/YY)'
          value={expiryDate}
          onKeyDown={onExpiryDateKeyDown}
          onChange={onExpiryDateChange}
          showErrorOnBlur={!showErrors}
          errorMessage={validateExpiryDate() ? '' : 'Please enter a valid expiry date (MM/YY).'}
        />
        <Input
          inputMode='numeric'
          type='password'
          autocomplete='cc-csc'
          id='cc-csc'
          name='cc-csc'
          label='Security code'
          placeholder={`Enter the security code ${cardNumber.detailed ? `(${cardNumber.detailed.code.name})` : ''}`}
          value={securityCode}
          onChange={(e) => setSecurityCode(e.target.value)}
          showErrorOnBlur={!showErrors}
          errorMessage={
            cardNumber.detailed
              ? securityCode.length === cardNumber.detailed.code.size
                ? ''
                : 'Please enter a valid security code.'
              : ''
          }
        />
      </form>

      <div>
        <div css='padding-bottom: 0.5rem;'>
          Reference:{' '}
          <span
            css={`
              font-family: ${(props) => props.theme.font.family.label};
            `}
          >
            {transactionInfo.order.ref}
          </span>
        </div>
        {transactionInfo.order.items.map((item, i) => (
          <div key={i} css='display: flex; justify-content: space-between; font-weight: 600; align-items: center;'>
            <div>{item.name}</div>
            <div css='font-size: 1.1rem;'>
              {item.cost.amount.toLocaleString(undefined, { style: 'currency', currency: item.cost.currency })}
            </div>
          </div>
        ))}

        <div
          css={`
            display: flex;
            justify-content: space-between;
            font-weight: 700;
            padding-top: 0.5rem;
            align-items: center;
            font-size: 1.2rem;
            color: ${(props) => props.theme.color.accent};
          `}
        >
          <div>Total Cost</div>
          <div>
            {transactionInfo.order.total.amount.toLocaleString(undefined, {
              style: 'currency',
              currency: transactionInfo.order.total.currency,
            })}
          </div>
        </div>
      </div>

      <Button onClick={pay} isValid={isValid}>
        PAY{' '}
        {transactionInfo.order.total.amount.toLocaleString(undefined, {
          style: 'currency',
          currency: transactionInfo.order.total.currency,
        })}
      </Button>
    </>
  )
}

export default PaymentForm
