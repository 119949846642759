import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { getTransaction, submitPayment } from './backend'
import BasicGlobalStyle from './basicGlobalStyle'
import PaymentForm from './views/PaymentForm'
import Success from './views/Success'
import Failure from './views/Failure'
import Loading from './views/Loading'
import Invalid from './views/Invalid'
import InnerHTML from 'dangerously-set-html-content'
import Cancelled from './views/Cancelled'

const Container = styled.main`
  margin-inline: auto;
  display: grid;
  width: 100%;
  gap: 1.5rem;
  padding: 1rem;

  max-width: ${(props) => props.maxWidth};
`

const LandingPage = () => {
  const token = window.location.pathname.replaceAll('/', '')
  const searchParams = new URLSearchParams(window.location.search)

  // take some default styling to apply before merchant styling is retrieved.
  const defaultBackgroundColor = '#' + (searchParams.get('backgroundColor') || 'ffffff')
  const defaultTextColor = '#' + (searchParams.get('textColor') || '202020')
  const maxWidth = searchParams.get('maxWidth')

  const [transactionInfo, setTransactionInfo] = useState()

  // invalid || callback || loading || payment_input || cust_verification_required || success || failure
  const [status, setStatus] = useState(token === '' ? 'invalid' : token.indexOf('___') > -1 ? 'callback' : 'loading')

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(async () => {
    if (token) {
      // console.log(token)
      const ti = await getTransaction(token.replace('___', ''))
      if (!ti.error) {
        setTransactionInfo((t) => ({ ...ti, payload: t?.payload }))
        if (token.indexOf('___') === -1) {
          setStatus('payment_input')
        }
      }
    }
  }, [token])

  useEffect(() => {
    if (status === 'callback') {
      const payload = searchParams.get('payload')
      if (payload !== undefined && payload !== null) {
        const decoded = JSON.parse(window.atob(payload.split('.')[1]))
        setTransactionInfo((t) => ({ ...t, payload: decoded }))
        console.table({ message: decoded })
        window.top.postMessage({ message: decoded }, '*')
      }
    } else {
      console.table({ message: { status: status } })
      window.top.postMessage({ message: { status: status } }, '*')
    }
  }, [status])

  const handlePayment = async (request) => {
    setStatus('loading')
    const resp = await submitPayment(request)
    setTransactionInfo((t) => ({ ...t, response: resp }))

    if (resp.error) {
      setStatus('api_failure')
      setErrorMessage(resp.error)
    } else if (resp.status === 'cust_verification_required') {
      if (resp.cust_verification_required.visit_url) {
        window.top.location.href = resp.cust_verification_required.visit_url
      } else {
        setStatus('cust_verification_required')
      }
    } else if (resp.status === 'failed') {
      setStatus('failure')
    } else if (resp.status === 'ok') {
      setStatus('success')
    } else {
      setStatus('invalid')
    }
  }

  return (
    <ThemeProvider
      theme={(theme) => ({
        ...theme,
        color: {
          ...theme.color,
          text: defaultTextColor,
          background: defaultBackgroundColor,
          ...transactionInfo?.customisation?.color,
        },
      })}
    >
      {/* including global style here because body background-color can be overwritten by theme */}
      <BasicGlobalStyle />
      <Container maxWidth={maxWidth}>
        {status === 'invalid' && <Invalid />}
        {status === 'loading' && <Loading />}
        {status === 'user_cancelled' && <Cancelled />}
        {status === 'success' && <Success />}
        {status === 'failure' && <Failure errorMessage={errorMessage} />}
        {status === 'api_failure' && <Failure errorMessage={errorMessage} apiFailure={true} />}
        {status === 'callback' && transactionInfo?.payload?.status === 'failed' && <Failure />}
        {status === 'callback' && transactionInfo?.payload?.status === 'ok' && <Success />}
        {status === 'cust_verification_required' && (
          <>
            <Loading />
            <InnerHTML html={transactionInfo.response.cust_verification_required.render_html} />
          </>
        )}
        {status === 'payment_input' && <PaymentForm transactionInfo={transactionInfo} handlePayment={handlePayment} />}
      </Container>
    </ThemeProvider>
  )
}

export default LandingPage
