import React from 'react'
import LoadIcon from '../assets/load.svg'
import { onDesktop, spin } from '../simple-components/styledElements'

const Loading = () => {
  return (
    <div
      css={`
        margin-block: 4em;
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        ${onDesktop`
          margin-block: 2em;
        `}
      `}
    >
      <div css='display: flex; align-items:center; justify-content: center; '>
        <LoadIcon
          css={`
            ${spin}
            height: 3.5rem;
            width: 3.5rem;
            fill: ${(props) => props.theme.color.text};
            path {
              fill: ${(props) => props.theme.color.text};
            }
          `}
        />
      </div>
    </div>
  )
}

export default Loading
