import React from 'react'
import CrossIcon from '../assets/icon-no-cross.svg'
// import Button from '../simple-components/Button'
import { onDesktop } from '../simple-components/styledElements'

const Failure = ({ errorMessage, apiFailure = false }) => {
  return (
    <div
      css={`
        margin-block: 3em;
        font-size: 1.5rem;
        font-family: ${(props) => props.theme.font.family.medium};
        font-weight: 700;
        color: ${(props) => props.theme.color.error};
        text-align: center;
        ${onDesktop`
          margin-block: 3em;
        `}
      `}
    >
      <CrossIcon css='height: 6rem; margin-bottom: 1rem;' />
      <div>{apiFailure ? 'This transaction has failed.' : 'This transaction has been declined.'}</div>
      <div
        css={`
          color: ${(props) => props.theme.color.text};
          font-size: 1rem;
          font-family: ${(props) => props.theme.font.family.regular};
        `}
      >
        {errorMessage}
      </div>
      {/* <div
        css={`
          display: flex;
          flex-direction: column-reverse;
          gap: 1rem;
          margin-inline: auto;
          margin-top: 2rem;
          button {
            margin-inline: auto;
          }
          ${onDesktop`
            flex-direction: row;
            margin-top: 2em;
            
            margin-inline: 0;

            button:first-child {
              margin-inline-start: auto;
            }
            button:last-child {
              margin-inline-end: auto;
            }
          `}
        `}
      >
        <Button
          css={`
            background-color: ${(props) => props.theme.color.buttonGreyed};
            :hover {
              background-color: ${(props) => props.theme.color.buttonGreyedHover};
              border-color: ${(props) => props.theme.color.accentHover};
            }

            :active {
              background-color: ${(props) => props.theme.color.buttonGreyedHover};
              border-color: ${(props) => props.theme.color.accentHover};
            }
          `}
          onClick={onCancel}
        >
          Cancel
        </Button>
        {showRetry && <Button>RETRY</Button>}
      </div> */}
    </div>
  )
}

export default Failure
