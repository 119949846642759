import React from 'react'
import CheckIcon from '../assets/icon-yes-tick.svg'
import { onDesktop } from '../simple-components/styledElements'

const Success = () => {
  return (
    <div
      css={`
        margin-block: 4em;
        font-size: 1.5rem;
        font-family: ${(props) => props.theme.font.family.medium};
        font-weight: 700;
        color: ${(props) => props.theme.color.success};
        text-align: center;
        ${onDesktop`
            margin-block: 2em;
          `}
      `}
    >
      <CheckIcon css='height: 6rem;' />
      <div>Payment successful.</div>
    </div>
  )
}

export default Success
