import styled from 'styled-components'

const Button = styled.button`
  border: none;
  margin: unset;

  border-style: none;
  border-radius: 4px;

  background-color: ${(props) => (props.isValid === false ? props.theme.color.buttonGreyed : props.theme.color.accent)};

  font-family: ${(props) => props.theme.font.family.label};
  font-size: 1rem;
  color: ${(props) => props.theme.color.accentText};

  width: 11rem;
  height: 2.5rem;

  display: flex;
  justify-self: center;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  :hover {
    box-shadow: 2px 2px 6px 2px ${(props) => props.theme.color.shadow};

    background-color: ${(props) =>
      props.isValid === false ? props.theme.color.buttonGreyedHover : props.theme.color.accentHover};
    border-color: ${(props) => props.theme.color.accentHover};
  }

  :active {
    box-shadow: 0px 0px 6px transparent;

    background-color: ${(props) => props.theme.color.accentHover};
    border-color: ${(props) => props.theme.color.accentHover};
  }

  &:disabled,
  &:disabled:hover {
    border-color: ${({ theme }) => theme.color.buttonGreyed};
    box-shadow: 0px 0px 6px transparent;
    color: ${({ theme }) => theme.color.buttonGreyed};
    background: unset;
    cursor: not-allowed;
  }

  :focus-visible {
    outline: ${({ theme }) => theme.color.outline} solid 1px;
  }
`

export default Button
